import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      instagram: file(relativePath: { eq: "instagram.png" }) {
        childImageSharp {
          fixed(width: 40, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      nzqa: file(relativePath: { eq: "nzqa.png" }) {
        childImageSharp {
          fluid(maxWidth: 380, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      careers: file(relativePath: { eq: "careers_new_zealand_logo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 380, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      studylink: file(relativePath: { eq: "studylink.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 380, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  //careers_new_zealand_logo.jpg

  return (
    <>
      <footer id="footer">
        <div className="container">
          <div className="row sections">
            <div className="col-xs-12 col-sm-4 recent_posts">
              <h3 className="footer_header">Contact</h3>
              <div className="contact-details">
                <div>
                  <a href="mailto:info@bodhi.ac.nz" className="footer-link">
                    Email: info@bodhi.ac.nz
                  </a>
                </div>
                <div>
                  <a href="tel:+640211851421" className="footer-link">
                    021 185 1421
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.instagram.com/bodhi_ltd/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Img
                      fixed={data.instagram.childImageSharp.fixed}
                      alt="Bodhi Instagram Account"
                      className="img-responsive insta"
                    />
                  </a>
                </div>
              </div>
              <div className="copyright">
                © {new Date().getFullYear()} Bodhi Ltd
              </div>
            </div>
            <div className="col-xs-12 col-sm-2 pull-right">
              <a
                href="https://www2.nzqa.govt.nz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fluid={data.nzqa.childImageSharp.fluid}
                  alt="NZQA"
                  className="img-responsive nzqa"
                />
              </a>
            </div>
            <div className="col-xs-12 col-sm-2 pull-right">
              <a
                href="https://www.careers.govt.nz/qualifications/view/2851/8022"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fluid={data.careers.childImageSharp.fluid}
                  alt="NZQA"
                  className="img-responsive careers-nz"
                />
              </a>
            </div>
            <div className="col-xs-12 col-sm-2 pull-right">
              <a
                href="https://www.studylink.govt.nz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fluid={data.studylink.childImageSharp.fluid}
                  alt="Study Link"
                  className="img-responsive studylink"
                />
              </a>
            </div>
          </div>
        </div>
      </footer>

      <div id="fb-pxl-ajax-code"></div>
    </>
  )
}

export default Footer
