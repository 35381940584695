import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

import Img from "gatsby-image"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const [showMobileMenu, setShowMobileMenu] = useState(false)

  return (
    <div
      id="navbar"
      className="navbar navbar-inverse navbar-fixed-top"
      role="navigation"
    >
      <div className="container-fluid">
        <div className="navbar-header">
          <Link className="navbar-brand" to={`/`}>
            <Img
              fixed={data.logo.childImageSharp.fixed}
              alt="Bodhi Education"
              className="logo"
            />
          </Link>
          <button
            type="button"
            id="bodhi__mobile-menu-toggle"
            className="btn bodhi__btn-inverse bodhi__mobile-menu-toggle"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            {showMobileMenu ? "Close" : "Menu"}
          </button>
        </div>

        <div
          className={
            showMobileMenu ? "bodhi__mobile-nav" : "collapse bodhi__mobile-nav"
          }
          style={{ display: showMobileMenu ? "inline-flex" : "inherit" }}
          role="navigation"
          id="bodhi-collapse"
        >
          <a href="/enrol" className="btn bodhi__header-apply-now-button">
            Apply Now
          </a>
          <ul className="nav navbar-nav navbar-right">
            <li>
              <Link className="page-link" to={`/about/`}>
                About
              </Link>
            </li>
            <li className="bodhi__header__nav-drop-down hidden-xs hidden-sm">
              <Link className="page-link" to={`/level-5/`}>
                Course
              </Link>
              <div className="bodhi__header__nav-drop-down-popup">
                <Link className="btn" to={`/level-5/`}>
                  Level 5
                </Link>
                <Link className="btn" to={`/level-6/`}>
                  Level 6
                </Link>
              </div>
            </li>
            <li className="hidden-md hidden-lg">
              <Link className="page-link" to={`/level-5/`}>
                Level 5
              </Link>
            </li>
            <li className="hidden-md hidden-lg">
              <Link className="page-link" to={`/level-6/`}>
                Level 6
              </Link>
            </li>
            <li>
              <Link className="page-link" to={`/enrol/`}>
                Enrol
              </Link>
            </li>
            <li>
              <Link className="page-link" to={`/pathways/`}>
                Pathways
              </Link>
            </li>
            <li>
              <Link className="page-link" to={`/testimonials/`}>
                Testimonials
              </Link>
            </li>
            <li>
              <a
                href="https://bodhi.learn.sebdata.com/login/"
                className="page-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Login
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
