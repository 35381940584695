/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        {/** Facebook Pixel Code */}
        <script type="text/javascript">
          {`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '555143709466853');
fbq('track', 'PageView');`}
        </script>
        <noscript>
          {`<img height="1" width="1" style={{ display: 'none' }} alt="fbpixel" src="https://www.facebook.com/tr?id=1005810090159338&ev=PageView&noscript=1"/>`}
        </noscript>

        <script type="text/javascript">
          {`!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js?v=next');`}
        </script>
        <script type="text/javascript">
          {`var url = window.location.origin + '?ob=open-bridge';fbq('set', 'openbridge', '645297513792324', url);`}
        </script>
        <script type="text/javascript">{`fbq('init', '645297513792324', {})`}</script>
        <script type="text/javascript">{`fbq('track', 'PageView', []);`}</script>
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-N5V6C8T');`}</script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-7XR5FYFJXK"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-7XR5FYFJXK');`}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none" alt="fbpx" src="https://www.facebook.com/tr?id=645297513792324&ev=PageView&noscript=1" />`}
        </noscript>
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
